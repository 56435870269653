<template>
  <img :width="width ?? 100" :height="height ?? 50" :src="src ?? '/logo.png'" />
</template>

<script>
export default {
  props: {
    src: String,
    height: Number,
    width: Number
  }
}
</script>
