<template>
  <div class="container mx-auto">
    <div class="flex justify-between mb-4" v-if="mode == 'cart'">
      <text-input placeholder="Buscar producto..."  id="search" class="p-2 border border-gray-300 rounded" v-model="form.search.terms" />
      <div>
        <select v-model="form.search.category" class="p-2 border border-gray-300 rounded">
          <option value="">Ver todas las categorías</option>
          <option v-for="category in product_categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3">
      <div class="col-span-2 mr-2">
        <div v-if="mode == 'cart'" class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <product-card v-for="product in products.data" :key="product.id" :product="product" @addCart="addCart" :change="change" :cop_change="cop_change"/>
        </div>
      </div>

      <div v-if="mode == 'cart'" class="mb-4 px-8 py-6 bg-white rounded-lg">
        <h2 class="mb-4 text-lg font-medium">Canasta</h2>

        <div v-for="(product, key) in cart" :key="product.id" class="flex items-center justify-between mb-4">
          <div class="flex items-center">
            <button @click="removeProduct(product)" class="p-2 text-white bg-red-500">
              <i class="fa fa-trash"></i>
            </button>
            <div class="ml-4">
              <h3 class="text-sm font-medium">{{ product?.name }}</h3>
              <p class="text-gray-500 text-xs">{{ product?.stock }} Unidades Disponibles</p>
            </div>
          </div>
          <div class="flex items-center">
            <text-input-rounded type="number" :max="product?.stock" v-model="cart[key]['quantity']" class="mr-2 w-10 border rounded" />
            <span class="text-md font-medium">X ${{ cart[key]['price'] }} </span>
            <span class="text-md font-medium"> = ${{ (cart[key]['price'] * product.quantity).toFixed(2) }}</span>
          </div>
        </div>
        <div v-if="!cart.length">No hay productos en el carrito</div>

        <div class="flex items-center justify-between">
          <span class="text-lg font-medium">Total</span>
          <div class="flex items-center">
            <span class="text-lg font-medium">${{ getTotal() }}</span>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <span class="text-lg font-medium">Total Bs</span>
          <div class="flex items-center">
            <span class="text-lg font-medium">{{ (getTotal() * change).toFixed(2) }}</span>
          </div>
        </div>

        <div class="mt-2">
          <input type="checkbox" v-model="withoutClient" name="" id="">
          Sin cliente?
        </div>
      </div>
      <div v-if="mode == 'client'" class="col-span-3 mb-4 px-8 py-6 bg-white rounded-lg">
        <h2 class="mb-4 text-lg font-medium">Datos del cliente</h2>

        <div>
          <div class="flex flex-wrap mb-8 mr-6 p-8">
          <!-- <text-input v-model="form.client.companyName" label="Empresa" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <div class="flex items-center pb-8 pr-6  w-full lg:w-1/2">
            <text-input v-model="form.client.identification" label="Alias" />
            <button class="btn-blue mt-5" @click="findClient" type="button"><i class="fa fa-search" /></button>
          </div>
          <text-input v-model="form.client.name" label="Nombre y Apellido de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <!-- <text-input v-model="form.client.email" label="Email de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <!-- <text-input v-model="form.client.identification" label="Cédula o RIF de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <text-input v-model="form.client.phone" label="Telefono de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.address" label="Direccion de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.balance" disabled="true" label="Monto adeudado" class="pb-8 pr-6 w-full lg:w-1/2" />
          <div class="pb-8 pr-6 w-full lg:w-1/2">
            <label for="">Tipo de cliente</label>
            <select v-model="form.client.typeClient" class="form-input mt-2 w-full">
              <option value="">Seleccionar</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>

          <!-- <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia Bs</option>
            <option value="transferencia-usd">Transferencia Divisas</option>
            <option value="biopago">Zelle</option>
            <option value="credito">Credito</option>
          </select>
          <text-input v-if="form.method && form.method !== 'efectivo'" v-model="form.referencePay" label="Referencia" class="mt-2 w-full" /> -->
        </div>
        </div>
      </div>

      <div v-if="mode == 'pay'" class="mb-4 px-8 py-6 bg-white rounded-lg">
        <h2 class="mb-4 text-lg font-medium">Datos de pago</h2>
        <div>
          <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia Bs</option>
            <option value="transferencia-usd">Transferencia Divisas</option>
            <option value="biopago">Zelle</option>
            <option value="credito">Credito</option>
          </select>
          <text-input v-if="form.method && form.method !== 'efectivo'" v-model="form.referencePay" label="Referencia" class="mt-2 w-full" />
          <text-input v-if="form.method && form.method === 'efectivo'" v-model="cambio" label="Dinero que dió" class="mt-2 w-full" />
        </div>
      </div>

      <div v-if="mode == 'print'" class="mb-4 px-8 py-6 bg-white rounded shadow-md">
        <h2 class="mb-4 text-lg font-medium">Comprobantes</h2>
        
        <div class="p-5">
          <button class="btn-blue" type="button">
            <i class="fa fa-print"></i> Nota de entrega
          </button>
          <button class="btn-blue" type="button">
            <i class="fa fa-print"></i> Nota de entrega
          </button>
          <button class="btn-blue" type="button">
            <i class="fa fa-print"></i> Térmica
          </button>
        </div>
      </div>
    </div>
    <div class="flex p-5 bg-white  items-center justify-between fixed bottom-0 left-0 right-0 ">
      <div>
        <span v-if="cambio" class="text-lg font-medium">Cambio: ${{ cambio - getTotal() }} | </span>
        <span class="text-lg font-medium">Total: ${{ getTotal() }} | BsD.{{ (getTotal() * change).toFixed(2) }} <span>| Cop. {{ ((getTotal() * change) * cop_change).toFixed(1) }}</span>  </span>
      </div>
       <div> <button v-if="mode != 'cart'" 
          @click="back"
          class="mt-4 px-4 py-2 text-white font-bold bg-black hover:bg-gray-700 rounded mr-2"
        >Atrás</button>
        <button 
          @click="next"
          class="mt-4 px-4 py-2 text-white font-bold bg-black hover:bg-gray-700 rounded"
          >Siguiente</button></div>
      </div>
  </div>
</template>

<script>
import LayoutFull from '../../Shared/LayoutFull.vue'
import ProductCard from '../../Shared/ProductCard.vue'
import TextInput from '../../Shared/TextInput.vue'
import TextInputRounded from '../../Shared/TextInputRounded.vue'
import pickBy from 'lodash/pickBy'
import SelectInput from '../../Shared/SelectInput.vue'
export default {
  components: { ProductCard, TextInput, TextInputRounded, SelectInput },
  layout: LayoutFull,
  props: {
    products: Object,
    change: Number,
    cop_change: Number,
    client: Object,
    product_categories: Object
  },
  data() {
    return {
      cambio: 0,
      showClientCreate: false,
      mode: 'cart',
      form: this.$inertia.form({
        search: {
          terms: '',
          category: ''
        },
        client: {
          identification: ''
        },
        products: null,
        isCredit: false,
        comentary: 'Compra rapida!',
        referencePay: '',
        quick: true
      }),
      cart: [],
      withoutClient: true
    }
  },
  watch: {
    'form.search.terms': function () {
      this.onSearchProductsChange();
    },
    'form.search.category': function ()
    {
      this.onSearchProductsChange();
    },
    'products': function(val)
    {
      if(this.products.data.length == 1)
      {
        let product = this.products['data'];
        if(product[0]['code'] == this.form.search.terms)
        {

          if (product[0].quantity <= 0) {
            this.form.search.terms = ''
            return alert('No hay stock de este producto')
          }

          let enabled = true // Para saber si ya existe el producto
          this.cart.forEach((prd) => {
            if (prd.id == product[0].id) {
              enabled = false
              prd.quantity += 1
            }
          })
          if (enabled) {
            this.cart.push({ ...product[0], stock: product[0].quantity, quantity: 1, price: product[0].prices[0]['price'], quick: false })
          }

          this.form.search.terms = ''
        }
      }
    }
  },
  methods: {
    getTotal() {
      let total = 0
      this.cart.forEach((product) => {
        total += product.price * product.quantity
      })
      return total.toFixed(2)
    },
    back() {
      if(this.mode = 'cart')
      {
        
      }

      if(this.mode = 'client')
      {
        this.mode = 'cart'
      }
    },
    next()
    {
      if(this.mode == 'cart')
      {
        if(this.cart.length == 0)
        {
          return alert("No puede continuar sin escoger un producto")
        }

        if(this.withoutClient)
        {
          this.form.client = {
            "name":"Cliente por defecto",
            "email":null,
            "phone":"0414409888391",
            "address":"San diego",
            "identification":"0000",
            "balance":0,
            "typeClient":"a",
            "companyName":null,
            "id": 1
          }
          this.form.products = this.cart
          return this.form.post('/orders')
        }
        this.mode = 'client'
      }else if(this.mode == 'client')
      {
        this.form.products = this.cart
        this.form.post('/orders')
      }else if(this.mode == 'pay')
      {
        this.mode = 'print'
      }
    },
    removeProduct(product) {
      if (confirm('Estas seguro que quieres eliminar este item?')) {
        let products = this.cart
        let newProducts = products.filter((prd) => prd.id != product.id)
        this.cart = newProducts
      }
    },
    addCart(product) {
      if (product.quantity <= 0) {
        return alert('No hay stock de este producto')
      }

      let enabled = true // Para saber si ya existe el producto
      this.cart.forEach((prd) => {
        if (prd.id == product.id) {
          enabled = false
          prd.quantity += 1
        }
      })
      if (enabled) {
        this.cart.push({ ...product, stock: product.quantity, quantity: 1, price: product?.prices[0]['price'], quick: false })
      }
    },
    onSearchProductsChange() {
      this.$inertia.get('/pos', pickBy({ search: this.form.search }), {
        preserveState: true,
        preserveScroll: true,
        replace: true,
      })
    },
    findClient() {
      this.$inertia.get('/pos', pickBy(this.form), { preserveState: true })

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar cliente'
          this.showClientCreate = true
          this.form.client = {
              name: this.client.name,
              email: this.client.email,
              phone: this.client.phone,
              address: this.client.address,
              identification: this.client.identification,
              balance: this.client.balance,
              typeClient: this.client.typeClient,
              companyName: this.client.companyName,
            }
        } else {
          alert('No se encontro cliente, puede crearlo en el siguiente formulario')
          this.showClientCreate = true
        }
      }, 1500)
    },
  },
  mounted() {
    document.getElementById("search").focus();
  }
}
</script>
