<template>
  <div>
    <Head title="Inventario" />
    <h1 class="mb-8 text-3xl font-bold">Lote / {{ getDate(load.created_at) }}</h1>
    <div class="bg-white rounded-xl shadow overflow-x-auto mb-5">
      <div class="m-5">
        <div class="flex justify-between">
        <div>
          <div><strong>Transporte:</strong> {{load.transport}}</div>
          <div><strong>Region:</strong> {{load.region}}</div>
        </div>
        <div>
          <button class="btn-blue"> Imprimir <i class="fa fa-print"></i></button>
        </div>
      </div>
      </div>
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Numero de orden</th>
          <th class="pb-4 pt-6 px-6">Client</th>
          <th class="pb-4 pt-6 px-6">Bultos</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="order in orders" :key="order.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order?.order?.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order?.order?.client.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              1
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order?.order?.total }}
            </Link>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay pedidos.</td>
        </tr>
      </table>
      <div class="m-5" v-if="load.status != 'process'">
        <div>
          <button class="btn-blue" @click="changeState()"> Marcar como liberado</button>
        </div>
      </div>
    </div>
    <!-- <button v-if="inventory.status === 'request' && (user.role == 'storer' || user.role == 'admin')" @click="aprobe" class="btn btn-indigo">
      Confirmar
    </button> -->
    <!-- <pagination class="mt-6" :links="products.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import Pagination from "@/Shared/Pagination.vue";

export default {
  components: {
    Head,
    Icon,
    Link,
    TextInput,
    Pagination
  },
  layout: Layout,
  props: {
    load: Object,
    orders: Object
  },
  data() {
    return {
      form: {
        qtys: [],
      },
    }
  },
  methods: {
    getDate(date)
    {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    },
    aprobe: function () {
      let confirm = window.confirm("Estas seguro que quieres aprobar esta solicitud?");
      if(confirm){
        this.$inertia.get(`/inventories/approve/${this.inventory.id}`);
      }
    },
    changeState() {
      this.$inertia.get(`/loads/${this.load.id}/changeState`)
    }
  }
}
</script>
