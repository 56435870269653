<template>
  <div>
    <Head title="Categoria de producto" />

    <app-toolbar name="Categoria de producto" namePlural="Categorias de producto" createHref="/product-categories/create" :exportButtons="false">
      <template v-slot:search>
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Trashed:</label>
          <select v-model="form.trashed" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">Con eliminadas</option>
            <option value="only">Solo eliminadas</option>
          </select>
        </search-filter>
      </template>
    </app-toolbar>

    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Nombre</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="productCategory in productCategories.data" 
            :key="productCategory.id" 
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/product-categories/${productCategory.id}/edit`">
                {{ productCategory.name }}
              </Link>
            </td>
          </tr>
          <tr v-if="productCategories.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="4">No encontramos Categoria de producto.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination class="mt-6" :links="productCategories.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import AppToolbar from '../../Shared/AppToolbar.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    AppToolbar
  },
  layout: Layout,
  props: {
    filters: Object,
    productCategories: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/productCategories', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
