<template>
  <div>
    <div class="flex items-center justify-between mb-6">
      <button class="mr-2 p-2 text-white bg-sky-700 rounded" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="mb-8 text-3xl font-bold">Crear venta</h1>
    </div>
    <Head title="Crear venta" />

    <div class="bg-white rounded-xl shadow overflow-hidden">
      <div class="p-5">
        <find-or-create-client @addClient="takeClient" />
        <div class="flex">
          <div class="w-1/2">
            <!-- <text-input v-model="form.client.identification" label="Cédula o RIF de Cliente" class="pb-3 pr-6 w-full lg:w-1/2" />
            <button class="btn-blue" href="/orders/create" @click="findClient">{{ textSearch }} <i class="fa fa-search" /></button> -->
          </div>
          <div class="w-1/2">
            
          </div>
        </div>
        <!-- Formulario de cliente -->
        <div v-if="showClientCreate" class="flex flex-wrap mb-8 mr-6 p-8">
          <!-- <text-input v-model="form.client.companyName" label="Empresa" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <text-input v-model="form.client.name" label="Nombre y Apellido de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <!-- <text-input v-model="form.client.email" label="Email de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <text-input v-model="form.client.identification" label="Cédula o RIF de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.phone" label="Telefono de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.address" label="Direccion de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.balance" disabled="true" label="Monto adeudado" class="pb-8 pr-6 w-full lg:w-1/2" />
          <div class="pb-8 pr-6 w-full lg:w-1/2">
            <label for="">Tipo de cliente</label>
            <select v-model="form.client.typeClient" class="form-input mt-2 w-full">
              <option value="">Seleccionar</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>

          <!-- <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia Bs</option>
            <option value="transferencia-usd">Transferencia Divisas</option>
            <option value="biopago">Zelle</option>
            <option value="credito">Credito</option>
          </select>
          <text-input v-if="form.method && form.method !== 'efectivo'" v-model="form.referencePay" label="Referencia" class="mt-2 w-full" /> -->
        </div>
      </div>
    </div>
    <div class="mt-5 bg-white rounded-xl shadow overflow-hidden">
      <div class="p-3">
        <div class="flex items-center justify-between mb-6">
          <div>
            <button class="btn-blue mr-2" href="/orders/create" @click="listProducts = !listProducts">
              <i class="fa fa-plus" />
            </button>
            <button class="btn-blue" href="/orders/create" @click="addQuickProduct()"><i class="fa fa-plus" /> Agregar producto rapido</button>
          </div>
          <div>
            <text-input placeholder="Buscar por términos" class="w-full" v-model="form.search.terms" />
          </div>
        </div>
        <div v-if="listProducts">
          <div class="flex">
            <!-- <select-input v-model="form.search.region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Region">
              <option value="">Seleccionar</option>
              <option v-for="(region, key) in regions" :key="key" :value="region.id">{{ region?.name }}</option>
            </select-input>
            <select-input v-model="form.search.part_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Parte">
              <option value="">Seleccionar</option>
              <option v-for="(part, key) in parts" :key="key" :value="part.id">{{ part?.name }}</option>
            </select-input> -->
          </div>
          <div class="overflow-x-auto">
            <table class="w-full whitespace-nowrap">
              <tr>
                <th>Codigo</th>
                <th>Producto</th>
                <th>Disponibles</th>
                <th>Precio</th>
              </tr>
              <tr v-for="(product, key) in products.data" :key="key" class="hover:bg-gray-100 focus-within:bg-gray-100" @click="selectProduct(product)">
                <td class="p-2 text-center">{{ product?.code }}</td>
                <td class="p-2 text-center">{{ product?.name }}</td>
                <td class="p-2 text-center">{{ product?.quantity }}</td>
                <td class="p-2 text-center">{{ product?.prices[0]['price'] }}</td>
              </tr>
            </table>
          </div>
        </div>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th class="pb-4 pt-6 px-6">Codigo</th>
              <th class="pb-4 pt-6 px-6">Producto</th>
              <th class="pb-4 pt-6 px-6">disponible</th>
              <th>Cantidad</th>
              <th class="pb-4 pt-6 px-6">Precio</th>
              <th class="pb-4 pt-6 px-6">Total</th>
              <td></td>
            </tr>
          </thead>
          <tr v-for="(product, key) in form.products" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="p-2 text-center border-t">
              <span v-if="!product.quick">
                {{ product.code }}
              </span>
              <span v-else>
                <text-input v-model="form.products[key]['code']" />
              </span>
            </td>
            <td class="p-2 text-center border-t">
              <span v-if="!product.quick">
                {{ product.name }}
              </span>
              <span v-else>
                <text-input v-model="form.products[key]['name']" />
              </span>
            </td>
            <!-- <td class="p-2 text-center border-t">
              <select-input class="pb-8 pr-6 w-full" v-if="!product.quick" v-model="form.products[key]['deposit']">
                <option value="">Seleccionar</option>
                <option v-for="(deposit, key) in getDeposits(product.inventory)" :key="key" :value="deposit.deposit.id">{{ deposit.deposit.name }}</option>
              </select-input>
              <text-input v-else v-model="form.products[key]['deposit']" placeholder="Ingrese el Alias" />
            </td> -->
            <td class="p-2 text-center border-t">
              <!-- <span v-if="!product.quick">{{ getProductStock(product.inventory, form.products[key]['deposit']) }}</span> -->
              {{product.stock}}
            </td>
            <td class="p-2 text-center border-t">
              <text-input v-if="!product.quick" v-model="form.products[key]['quantity']" :max="form.products[key]['stock']" />
              <text-input v-else v-model="form.products[key]['quantity']" />
            </td>
            <td class="p-2 text-center border-t">
              <span v-if="!product.quick">
                <text-input v-if="product.prices.length" v-model="form.products[key]['price']" :value="product?.prices[0]['price']" />
              </span>
              <span v-else>
                <text-input v-model="form.products[key]['price']" />
              </span>
            </td>
            <td class="p-2 text-center border-t">
              {{ (form.products[key]['price'] * product.quantity).toFixed(2) }}
            </td>
            <td class="p-2 text-center border-t">
              <button @click="removeProduct(product)" class="p-2 text-white bg-red-500 rounded-full">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <h2 style="font-size: 16pt; font-weight: bold">Total: ${{ getTotal() }}</h2>
                <h2 style="font-size: 16pt; font-weight: bold">Total: BsD. {{ (getTotal() * form.change).toFixed(2) }}</h2>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div>
      <div class="p-5 bg-blue-300 rounded hidden">
        <input v-model="form.isCredit" type="checkbox" name="isCredit" id="" />
        <label for="" class="ml-2 text-xl font-bold">Crédito</label>
      </div>
    </div>

    <div class="rounded-xl mt-5 p-5 bg-white shadow overflow-hidden">
      <text-input v-model="form.number_order" label="Correlativo" placeholder="Nota: Este es el correlativo, debe ser unico. En caso de no agregarlo, el sistema agregara uno automaticamente" class="pb-8 pr-6 w-full" />
      <text-input v-model="form.order_buy" label="Orden de compra" placeholder="Indique si esta venta es por medio de una orden de compra" class="pb-8 pr-6 w-full" />
      <text-input v-model="form.comentary" label="Observacion (obligatorio)" class="pb-8 pr-6 w-full" />
      <text-input v-model="form.change" label="Cambio" class="pb-8 pr-6 w-full" />
    </div>
    
    <div class="flex items-center justify-between mt-6">
      <button @click="generateOrder" class="btn-blue" href="/orders/create">Generar Venta</button>
    </div>
  </div>
</template>

<script>
import Layout from '../../Shared/Layout.vue'
import TextInput from '@/Shared/TextInput'
import pickBy from 'lodash/pickBy'
import { Head } from '@inertiajs/inertia-vue3'
import SelectInput from '@/Shared/SelectInput.vue'
import FindOrCreateClient from '../../Shared/FindOrCreateClient.vue'

export default {
  components: {
    SelectInput,
    TextInput,
    Head,
    FindOrCreateClient,
  },
  layout: Layout,
  props: {
    products: Object,
    client: Object,
    brands: Object,
    parts: Object,
    models: Object,
    deposits: Object,
    regions: Object,
    change: Number,
  },
  data() {
    return {
      // eslint-disable-next-line no-unused-vars
      getQuantity(inventory) {
        let quantity = 0
        for (let inventory of inventory) {
          quantity += inventory.quantity
        }

        return quantity
      },
      getTotal() {
        let total = 0
        this.form.products.forEach((product) => {
          total += product.price * product.quantity
        })
        return total.toFixed(2)
      },
      selected: null,
      options: ['list', 'of', 'options'],
      form: this.$inertia.form({
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: '',
          region_id: '',
        },
        isCredit: false,
        number_order: null,
        client: {
          name: '',
          email: '',
          cedula: '',
          telefono: '',
          address: '',
          identification: '',
          balance: 0,
          typeClient: '',
        },
        comentary: '',
        products: [],
        method: '',
        referencePay: '',
        change: this.change,
        estimate: true,
        order_buy: ''
      }),
      listProducts: false,
      showClientCreate: false,
      textSearch: 'Buscar cliente',
    }
  },
  watch: {
    'form.search.terms': function () {
      this.onSearchProductsChange()
    },
    'form.search.deposit': function () {
      this.onSearchProductsChange()
    },
    'form.search.brand_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.part_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.truck_model_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.region_id': function () {
      this.onSearchProductsChange()
    },
  },
  methods: {
    takeClient(client)
    {
      this.form.client = client;
    },
    getDeposits(deposits) {
      return deposits
    },
    back() {
      window.history.back()
    },
    addQuickProduct() {
      this.form.products.push({ id: 'quick', code: '', name: '', deposit: '', quantity: 1, price: 0, quick: true })
    },
    getProductStock(inventory, deposito) {
      let stock = ''
      inventory.forEach((inv) => {
        if (inv.deposit.id == deposito) {
          stock = inv.quantity
        }
      })
      return stock
    },
    selectProduct(product) {
      if (product.quantity <= 0) {
        return alert('No hay stock de este producto')
      }

      let enabled = true // Para saber si ya existe el producto
      this.form.products.forEach((prd) => {
        if (prd.id == product.id) {
          enabled = false
        }
      })
      if (enabled) {
        this.form.products.push({ ...product, stock: product.quantity, quantity: 1, price: product?.prices[0]['price'], quick: false })
      }
    },
    removeProduct(product) {
      if (confirm('Estas seguro que quieres eliminar este item?')) {
        let products = this.form.products
        let newProducts = products.filter((prd) => prd.id != product.id)
        this.form.products = newProducts
      }
    },
    findClient() {
      this.$inertia.get('/orders/create', pickBy(this.form), { preserveState: true })
      this.textSearch = 'Buscando...'

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar cliente'
          this.showClientCreate = true
          this.form = this.$inertia.form({
            isCredit: false,
            number_order: null,
            client: {
              name: this.client.name,
              email: this.client.email,
              phone: this.client.phone,
              address: this.client.address,
              identification: this.client.identification,
              balance: this.client.balance,
              typeClient: this.client.typeClient,
              companyName: this.client.companyName,
            },
            search: {
              terms: '',
              deposit: '',
              brand_id: '',
              part_id: '',
              truck_model_id: '',
              region_id: '',
            },
            comentary: '',
            products: [],
            method: '',
            referencePay: '',
            estimate: true
          })
        } else {
          this.textSearch = 'Buscar cliente'
          alert('No se encontro cliente, puede crearlo en el siguiente formulario')
          this.showClientCreate = true
        }
      }, 1500)
    },
    generateOrder() {
      this.form.post('/orders')
    },
    onSearchProductsChange() {
      this.$inertia.get('/orders/create', pickBy({ search: this.form.search }), {
        preserveState: true,
        preserveScroll: true,
        replace: true,
      })
    },
    onSelectedProduct(consultant) {
      console.log(consultant)
    },
    updateSelected(newSelected) {
      this.selectProduct = newSelected
    },
  },
}
</script>
