<template>
  <div>
    <Head title="Grupos" />
    <h1 class="mb-8 text-3xl font-bold">Grupos</h1>
    <div class="flex items-center justify-between mb-6">
      <Link class="btn-blue" href="/groups/create">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Grupo</span>
      </Link>
    </div>
    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Description</th>
          <th>creado por</th>
        </tr>
        <tr v-for="group in groups" :key="group.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/groups/${group.id}/edit`">
              {{ group.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/groups/${group.id}/edit`">
              {{ group.description }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/groups/${group.id}/edit`">
              {{ group.user.first_name }} {{ group.user.last_name }}
            </Link>
          </td>
        </tr>
        <tr v-if="!groups">
          <td class="px-6 py-4 border-t" colspan="4">No hay grupos.</td>
        </tr>
      </table>
    </div>
    <!-- <pagination class="mt-6" :links="vendors.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Head,
    Icon,
    Link,
  },
  layout: Layout,
  props: {
    groups: Object,
  },
}
</script>
