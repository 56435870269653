<template>
  <div class="fixed bottom-0 left-0 right-0 bg-white shadow md:hidden lg:hidden">
    <div class="flex justify-between px-4 py-5">
      <Link class="items-center justify-center" href="/">
        <icon name="dashboard" class="mr-2 w-5 h-5" :class="isUrl('') ? 'fill-black' : 'fill-gray-600 group-hover:fill-black'" />
        <div :class="isUrl('') ? 'text-black' : 'text-gray-600 group-hover:text-black'">Escritorio</div>
      </Link>
      <div v-if="role == 'admin' || role == 'endline' || role == 'storer'" class="mb-4">
        <Link class="items-center justify-center py-3" href="/products">
          <i :class="isUrl('products') ? 'fa fa-shopping-cart mr-2 text-black' : 'fa fa-shopping-cart mr-2 text-gray-600 group-hover:fill-black'" />
          <div :class="isUrl('products') ? 'text-black' : 'text-gray-500 group-hover:text-black'">Productos</div>
        </Link>
      </div>
      <div class="mb-4" v-if="role == 'admin' || role == 'sales' || role == 'financial'">
        <Link class="items-center justify-center py-3" href="/clients">
          <i :class="isUrl('clients') ? 'fa fa-address-book mr-2 text-black' : 'fa fa-address-book mr-2 text-gray-600 group-hover:fill-black'" />
          <div :class="isUrl('clients') ? 'text-black' : 'text-gray-500 group-hover:text-black'">Clientes</div>
        </Link>
      </div>
      <div v-if="role == 'sales' || role == 'admin' || role == 'storer' || role == 'financial'" class="mb-4">
        <Link class="items-center justify-center py-3" href="/orders">
          <i :class="isUrl('orders') ? 'fa fa-shopping-cart mr-2 text-black' : 'fa fa-shopping-cart mr-2 text-gray-600 group-hover:fill-black'" />
          <div :class="isUrl('orders') ? 'text-black' : 'text-gray-500 group-hover:text-black'">Ventas</div>
        </Link>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
    Link,
  },
  data() {
    return {
      role: this.$parent.auth.user.role,
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1)
      if (urls[0] === '') {
        return currentUrl === ''
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length
    },
  },
}
</script>
