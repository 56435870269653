<template>
  <div>
    <Head title="Depositos" />
    
    <app-toolbar name="Deposito" namePlural="Depositos" createHref="/deposits/create" :exportButtons="false" />

    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Alias</th>
          <th class="pb-4 pt-6 px-6">Región</th>
          <th class="pb-4 pt-6 px-6">Dirección</th>
        </tr>
        <tr v-for="deposit in deposits['data']" :key="deposit.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits/${deposit.id}/edit`">
              {{ deposit.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits/${deposit.id}/edit`">
              {{ deposit.alias }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits/${deposit.id}/edit`">
              {{ deposit.region.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits/${deposit.id}/edit`">
              {{ deposit.address }}
            </Link>
          </td>
        </tr>
        <tr v-if="!deposits">
          <td class="px-6 py-4 border-t" colspan="4">No hay depositos.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="deposits.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import AppToolbar from '../../Shared/AppToolbar.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    AppToolbar,
  },
  layout: Layout,
  props: {
    deposits: Object,
  },
}
</script>
