<template>
  <div>
    <Head title="Productos" />
    <h1 class="mb-8 text-3xl font-bold">Lote de cargas</h1>
    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <div class="p-5">
        <h3>Buscar ordenes</h3>
      </div>
      
      <div class="px-10">
        <div class="flex">
        <text-input label="Por Términos" class="pb-8 pr-6 w-full lg:w-1/2" v-model="form.search.number_order" />
      </div>
      <div class="overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <tr>
            <th>Numero</th>
            <th>Cliente</th>
            <th>Total</th>
          </tr>
          <tr v-for="(order, key) in orders" :key="key" class="hover:bg-gray-100 focus-within:bg-gray-100" @click="addProduct(order)">
            <td class="p-2 text-center">{{ order?.number_order }}</td>
            <td class="p-2 text-center">{{ order?.client?.name }}</td>
            <td class="p-2 text-center">{{order?.total}}</td>
          </tr>
        </table>
      </div>
      </div>


      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-3">Numero</th>
          <th class="pb-4 pt-6 px-3">Cliente</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="(order, key) in items" :key="key" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.client.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.total }}
            </Link>
          </td>
          <td class="border-t">
            <button @click="removeProduct(order)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay ordenes.</td>
        </tr>
      </table>

      <div class="px-10">
        <select-input v-model="form.transport" label="Transporte">
          <option value="">Seleccionar</option>
          <option value="apj">APJ</option>
          <option value="syt">S&T</option>
          <option value="internal">Interno</option>
        </select-input>
        <select-input v-model="form.region" label="Region">
          <option value="">Seleccionar</option>
          <option value="andina">Andina</option>
          <option value="oriente">Oriente</option>
          <option value="llanera">Llanera</option>
        </select-input>
      </div>
      <loading-button class="btn-blue mx-4 my-4" type="button" @click="generate">Crear lote de carga</loading-button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import pickBy from 'lodash/pickBy'
import SelectInput from '@/Shared/SelectInput.vue'

export default {
  components: {
    Head,
    Link,
    TextInput,
    SelectInput,
  },
  layout: Layout,
  props: {
    orders: Object,
  },
  data() {
    return {
      getQuantity(inventory)
      {
        let quantity = 0
        for (let inventory of inventory) {
          quantity += inventory.quantity
        }

        return quantity
      },
      quantities: this.$inertia.form({
        qtys: [],
        products: [],
        deposits: [],
      }),
      form: this.$inertia.form({
        code: '',
        search: {
          number_order: ''
        },
        orders: [],
        transport: '',
        region: ''
      }),
      prd: {
        name: '',
      },
      items: []
    }
  },
  watch: {
    'form.search.number_order': function () {
      this.onSearchProductsChange()
    },
    'form.search.deposit': function () {
      this.onSearchProductsChange()
    },
    'form.search.brand_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.part_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.truck_model_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.region_id': function () {
      this.onSearchProductsChange()
    },
  },
  methods: {
    removeProduct(product) {
      if (confirm('Estas seguro que quieres eliminar este item?')) {
        let products = this.items
        let newProducts = products.filter((prd) => prd.id != product.id)
        this.items = newProducts
      }
    },
    onSearchProductsChange() {
      this.$inertia.get('/loads/create', pickBy({search: this.form.search}), {
        preserveState: true,
        preserveScroll: true,
        replace: true
      })
    },
    generate() {
      this.form.post('/loads')
    },
    addProduct(order) {
        this.form.orders.push(order);
        this.items.push(order);
    },
  },
}
</script>
