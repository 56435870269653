<template>
  <div>
    <Head title="Transferencia de Productos" />
    <h1 class="mb-8 text-3xl font-bold">Crear transferencia de Productos {{ deposit.name }}</h1>
    <div>
      <div class="flex">
        <text-input label="Por Términos" class="pb-8 pr-6 w-full lg:w-1/2" v-model="form.search.terms" />
        <!-- <select-input v-model="form.search.region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Region">
          <option value="">Seleccionar</option>
          <option v-for="(region, key) in regions" :key="key" :value="region.id">{{ region.name }}</option>
        </select-input> -->
      </div>
      <div class="p-5 bg-gray-200">
        <table class="w-full whitespace-nowrap">
          <tr>
            <th>Codigo</th>
            <th>Producto</th>
            <th>disponibles</th>
          </tr>
          <tr v-for="(product, key) in products.data" :key="key" class="hover:bg-gray-100 focus-within:bg-gray-100" @click="selectProduct(product)">
            <td class="p-2 text-center">{{ product.code }}</td>
            <td class="p-2 text-center">{{ product.name }}</td>
            <!-- <td class="p-2 text-center">
                  <span v-for="model in product.models" :key="model.id">
                    {{ model.truck_model.name }},
                  </span>
                </td> -->
            <td class="p-2 text-center">{{ product.quantity }}</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="p-5">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Deposito</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, key) in form.products" :key="product.id">
              <td class="p-2 text-center">
                {{ product.name }}
              </td>
              <td class="p-2 text-center">
                <select-input v-model="form.products[key]['deposit']" class="pb-8 pr-6 w-full lg:w-1/2" label="">
                  <option value="">Seleccionar</option>
                  <option v-for="deposit in deposits" :key="deposit.id" :value="deposit.id">{{ deposit.name }}</option>
                </select-input>
              </td>
              <td class="p-2 text-center">
                <text-input v-if="getProductStock(product.inventory, deposit.id) > 0" label="" class="pb-8 pr-6 w-full lg:w-1/2" v-model="form.products[key]['quantity']" type="number" :max="product.stock" />
              </td>
              <td class="p-2 text-center">
                <button class="text-red-500" @click="removeProduct(product.id)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button 
          class="btn-blue"
          @click="generate()"
        >Generar</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput'
import pickBy from 'lodash/pickBy'

export default {
  components: {
    Head,
    Icon,
    Link,
    SelectInput,
    TextInput,
  },
  layout: Layout,
  props: {
    depositTransfers: Object,
    depositId: Number,
    products: Object,
    brands: Object,
    parts: Object,
    models: Object,
    deposits: Object,
    regions: Object,
    deposit: Object,
  },
  watch: {
    'form.search.terms': function () {
      this.onSearchProductsChange()
    },
    'form.search.deposit': function () {
      this.onSearchProductsChange()
    },
    'form.search.brand_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.part_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.truck_model_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.region_id': function () {
      this.onSearchProductsChange()
    },
  },
  data() {
    return {
      getProductStock(inventory, deposito) {
      let stock = 0;
      inventory.forEach(inv => {
          if(inv.deposit_id == deposito)
          {
            stock = inv.quantity;
          }
          
      })
      return stock
    },
      getQuantity(inventory, deposito) {
        let quantity = 0
        for (let inv of inventory) {
          if(inv.deposit_id == deposito)
          {
            console.log("Estoy aqui en getQuantity")
            quantity += inv.quantity
          }
        }

        return quantity
      },
      form: this.$inertia.form({
        search: {
          terms: '',
          deposit: this.depositId,
          brand_id: '',
          part_id: '',
          truck_model_id: '',
        },
        products: [],
        amount: 0
      }),
    }
  },
  methods: {
    onSearchProductsChange() {
      console.log(this.form.search)
      this.$inertia.get(`/deposits/${this.depositId}/transfer/create`, pickBy({ search: this.form.search }), {
        preserveState: true,
        preserveScroll: true,
        replace: true,
      })
    },
    selectProduct(product) {
      let enabled = true // Para saber si ya existe el producto
      this.form.products.forEach((prd) => {
        if (prd.id == product.id) {
          enabled = false
        }
      })

      if (enabled) {
        this.form.products.push({ ...product, stock: product.quantity, quantity: 0, quick: false, enabled: true })
      }
    },
    generate()
    {
        let validate = true;
        // Debemos comprobar que las cantidades sean mayor a cero
        this.form.products.forEach((product) => {
          if(product.quantity <= 0)
          {
            validate = false;
          }
        });

        if(!validate)
        {
          return alert("No se puede procesar la transferencia, revisa los items que quieres tranferir");
        }

        return this.form.post(`/deposits/${this.depositId}/transfer`);


    },
    removeProduct(productId)
    {
      if (confirm('Estas seguro que quieres eliminar este item?')) {
        let products = this.form.products
        let newProducts = products.filter((prd) => prd.id != productId)
        this.form.products = newProducts
      }
    }
  },
}
</script>
