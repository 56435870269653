<template>
  <div>
    <div id="dropdown" />
    <div class="md:flex md:flex-col">
      <div class="md:flex md:flex-col md:h-screen">
        <div class="md:flex md:flex-shrink-0">
          <div class="flex items-center justify-between md:flex-shrink-0 md:justify-center md:w-56 bg-white">
            <Link class="mt-1 mb-1" href="/">
              <logo :src="auth.user.account.logo" class="fill-white" width="120" height="80" />
            </Link>
            <dropdown class="md:hidden" placement="bottom-end">
              <template #default>
                <svg class="w-6 h-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
              </template>
              <template #dropdown>
                <div class="mt-2 px-8 py-4 bg-sky-700 rounded shadow-lg">
                  <main-menu :auth="auth" :account="auth.user.account" />
                </div>
              </template>
            </dropdown>
          </div>

          <div class="md:text-md flex items-center justify-between p-4 w-full text-sm bg-white border-b md:px-12 md:py-0">
            <div class="mr-4 mt-1">1$ = {{ change }} BsD</div>
            <div class="font-semibold text-xl">
              
            </div>
            <dropdown class="mt-1" placement="bottom-end">
              <template #default>
                <div class="group flex items-center cursor-pointer select-none">
                  <div class="mr-1 text-gray-700 group-hover:text-blue-600 focus:text-blue-600 whitespace-nowrap">
                    <span>{{ auth.user.first_name }}</span>
                    <span class="hidden md:inline">&nbsp;{{ auth.user.last_name }}</span>
                  </div>
                  <icon class="w-5 h-5 fill-gray-700 group-hover:fill-blue-600 focus:fill-blue-600" name="cheveron-down" />
                </div>
              </template>
              <template #dropdown>
                <div class="mt-2 py-2 text-sm bg-white rounded shadow-xl">
                  <Link class="block px-6 py-2 hover:text-white hover:bg-blue-500" :href="`/users/${auth.user.id}/edit`">Mi perfil</Link>
                  <Link v-if="auth.user.role === 'admin'" class="block px-6 py-2 hover:text-white hover:bg-blue-500" href="/users">Usuarios</Link>
                  <Link v-if="auth.user.role === 'admin'" class="block px-6 py-2 hover:text-white hover:bg-blue-500" :href="`/accounts/${auth.user?.account?.id}`">Cuenta</Link>
                  <Link class="block px-6 py-2 w-full text-left hover:text-white hover:bg-blue-500" href="/logout" method="delete" as="button">Cerrar sesión</Link>
                </div>
              </template>
            </dropdown>
          </div>
        </div>
        <div class="md:flex md:flex-grow md:overflow-hidden">
          <main-menu :account="auth.user.account" class="hidden flex-shrink-0 p-12 w-56 overflow-y-auto md:block rounded-b-lg" :style="{'backgroundColor': auth.user.account.sidebarColor ?? 'white'}" />
          <div class="px-4 py-8 md:flex-1 md:p-12 md:overflow-y-auto bg-[#ecf0f1]" scroll-region>
            <flash-messages />
            <slot />
          </div>
        </div>
      </div>
    </div>
    <bottom-menu />
  </div>
</template>
<style scoped>
  .background {
    background-color: #bbe8e1;
  }
</style>
<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import Dropdown from '@/Shared/Dropdown'
import MainMenu from '@/Shared/MainMenu'
import FlashMessages from '@/Shared/FlashMessages'
import BottomMenu from './BottomMenu.vue'

export default {
  components: {
    Dropdown,
    FlashMessages,
    Icon,
    Link,
    Logo,
    MainMenu,
    BottomMenu
  },
  props: {
    auth: Object,
    change: Number
  },
  data() {
    return {
      date: '',
      time: ''
    }
  },
  mounted() {
    
  },
  methods: {
    zeroPadding: (num, digit) => {
      var zero = '';
      for(var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    update: () => {
      let cd = new Date();
      let hours = '';
      let minutes = '';
      let secounds = '';
      let zero = '';

      for(let i = 0; i < 1; i++) {
        zero += '0';
      }
      hours = cd.getHours() > 9 ? cd.getHours() : (zero + cd.getHours())
      minutes =  cd.getMinutes() > 9 ? cd.getMinutes() : (zero + cd.getMinutes())
      secounds = cd.getSeconds() > 9 ? cd.getSeconds() :(zero + cd.getSeconds())

      return hours + ':' + minutes + ':' + secounds
    },
  }
}
</script>
