<template>
  <div :class="$attrs.class">
    <label v-if="label" class="form-label" :for="id">{{ label }}:</label>
    <input :id="id" ref="input" v-bind="{ ...$attrs, class: null }" class="form-input"  :class="{ error: error }" :type="type" :value="modelValue" @input="input" />
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    label: String,
    modelValue: String,
    max: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    input($event)
    {
      if(this.max)
      {
        if( parseFloat($event.target.value) > parseFloat(this.max))
        {
          alert('No puedes seleccionar una cantidad mayor al stock')
          return this.$emit('update:modelValue', '1')
        }
        
      }

      this.$emit('update:modelValue', $event.target.value)
    },
  },
}
</script>
