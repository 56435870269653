<template>
  <div>
    <Head title="Ventas" />

    <app-toolbar
      name="Venta"
      namePlural="Ventas"
      createHref="/orders/create"
      :exportButtons="true"
    >
      <template v-slot:search>
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset"> </search-filter>
      </template>
      <template v-slot:exportArea>
        <a :href="`/ordersDaily`" target="_blank" class="p-2 bg-red-900 mr-2 rounded-xl text-white"><i class="fa fa-print"></i> </a>
        <Link v-if="user.role === 'financial' || user.role === 'admin'" target="_blank" :href="`/ordersReportFinancial`" class="p-2 bg-green-500 mr-2 rounded-xl text-white"><i class="fa fa-print"></i> </Link>
      </template>
    </app-toolbar>

    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">#</th>
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Observacion</th>
          <th class="pb-4 pt-6 px-6">Cliente</th>
          <th class="pb-4 pt-6 px-6">Vendedor</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="order in orders.data" :key="order.id" :class="`hover:bg-gray-100 focus-within:bg-gray-100 ${order.order_state == 'cancel' ? 'bg-red-200' : ''}`">
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.date }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              <span v-if="order.order_state == 'estimate'" class="bg-blue-300 rounded p-1">PRESUPUESTO</span>
              <span v-if="order.order_state == 'cancel'" class="bg-blue-300 rounded p-1">ANULADA</span>
              <span v-if="order.order_state == 'request'" class="bg-blue-300 rounded p-1">PENDIENTE POR PAGO</span>
              <span v-if="order.order_state == 'finish'" class="bg-blue-300 rounded p-1">FINALIZADA</span>
              <span v-if="order.order_state == 'pay'" class="bg-blue-300 rounded p-1">PAGADA</span>
              <span v-if="order.order_state == 'delivery'" class="bg-blue-300 rounded p-1">EN TRANSPORTE</span>
              <span v-if="order.order_state == 'packing'" class="bg-blue-300 rounded p-1">PACKING</span>
              <span v-if="order.order_state == 'picking'" class="bg-blue-300 rounded p-1">PICKING</span>
              <span v-if="order.order_state == 'dispatch'" class="bg-blue-300 rounded p-1">EN LOTE DE CARGA</span>
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.comentary }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.client.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.responsible ? order.responsible.first_name + ' ' + order.responsible.last_name : 'Transaccion directa' }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500"> ${{ order.total }} </Link>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay Ordenes.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="orders.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import { getState } from '@/utils'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import AppToolbar from '../../Shared/AppToolbar.vue'

export default {
  components: {
    SearchFilter,
    Head,
    Icon,
    Link,
    Pagination,
    AppToolbar,
  },
  layout: Layout,
  data() {
    return {
      form: {
        search: '',
      },
      states: [
        {
          key: 'request',
          name: 'Solicitud',
        },
        {
          key: 'estimate',
          name: 'Presupuesto',
        },
        {
          key: 'pay',
          name: 'Pago',
        },
        {
          key: 'payRevition',
          name: 'Pago en revision',
        },
        {
          key: 'dispatch',
          name: 'Despachado',
        },
        {
          key: 'delivery',
          name: 'Liberado',
        },
        {
          key: 'finish',
          name: 'Finalizado',
        },
      ],
      getStateName(state) {
        return getState(state)
      },
    }
  },
  props: {
    orders: Object,
    user: Object,
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/orders', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {},
    back() {
      window.history.back()
    },
  },
}
</script>
