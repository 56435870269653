<template>
  <div>
    <Head title="Vendedores" />
    <h1 class="mb-8 text-3xl font-bold">Vendedores</h1>
    <div class="flex items-center justify-between mb-6">
      <Link class="btn-blue" href="/vendors/create">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Vendedor</span>
      </Link>
    </div>
    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre y Apellido</th>
          <th class="pb-4 pt-6 px-6">Correo electrónico</th>
          <th class="pb-4 pt-6 px-6" >Teléfono</th>
          <th class="pb-4 pt-6 px-6">Responsable</th>
        </tr>
        <tr v-for="vendor in vendors.data" :key="vendor.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/vendors/${vendor.id}/edit`">
              <!-- <img v-if="vendor.user.photo" class="block -my-2 mr-2 w-5 h-5 rounded-full" :src="vendor?.user?.photo" /> -->
              {{ vendor?.user?.first_name }} {{ vendor?.user?.last_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/vendors/${vendor.id}/edit`" tabindex="-1">
              {{ vendor?.user?.email }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/vendors/${vendor.id}/edit`" tabindex="-1">
              {{ vendor?.phone }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/vendors/${vendor.id}/edit`" tabindex="-1">
              {{ vendor?.responsible?.first_name }} {{ vendor?.responsible?.last_name }}
            </Link>
          </td>
          <td class="w-px border-t">
            <Link class="flex items-center px-4" :href="`/vendors/${vendor.id}/edit`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="vendors.length === 0">
          <td class="px-6 py-4 border-t" colspan="4">No encontramos vendedores.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  components: {
    Head,
    Icon,
    Link,
    SearchFilter,
  },
  layout: Layout,
  props: {
    vendors: Array,
  },
  data() {
    return {
      form: {

      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/vendors', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
