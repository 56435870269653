<template>
  <div>
    <Head title="Productos" />
    <div class="flex justify-between items-center">
      <button class="mr-2 p-2 text-white bg-sky-700 rounded" @click="back()">
      <i class="fa fa-arrow-left"></i>
    </button>
    <h1 class="mb-8 text-3xl font-bold">Productos</h1>
    </div>
    <div class="flex items-center justify-between mb-6">
      <div>
        <Link v-if="auth.user.role != 'supersales'" class="btn-blue mr-2" href="/products/create">
          <span>Crear</span>
          <span class="hidden md:inline">&nbsp;Producto</span>
        </Link>
      </div>

      <div>
        <button class="btn-blue mr-2" @click="searchForm = !searchForm">
          <i class="fa fa-search"></i>
        </button>

        <a href="/products/export" class="btn-blue mr-2"> Exportar </a>
        <button class="btn-blue" @click="imported = !imported">Importar</button>
        <form v-if="imported" action="/products/import" method="post" enctype="multipart/form-data">
          <input name="_token" type="hidden" :value="csrf" />
          <input type="file" name="file" id="" />
          <button type="submit" class="btn-blue">
            <i class="fa fa-upload"></i>
          </button>
        </form>
      </div>
    </div>

    <div v-if="searchForm" class="flex p-2 border-blue-100">
      <text-input label="Por Términos" class="pb-8 pr-6 w-full lg:w-1/2" v-model="form.search.terms" />
    </div>

    <div class="my-2">
      <h3 class="py-2 text-xl font-bold">Auxiliares</h3>
      <div class="flex flex-wrap gap-2">
        <a href="/product-categories" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Categorias de producto</a>
        <a href="/brands" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Marcas</a>
      </div>
    </div>

    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Codigo</th>
          <!-- <th class="pb-4 pt-6 px-6" style="width: 100px;">Depositos</th> -->
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Categoria de producto</th>
          <th class="pb-4 pt-6 px-6">Marca</th>
          <th class="pb-4 pt-6 px-6">Cantidades</th>
          <th v-if="auth.user.role != 'supersales'" class="pb-4 pt-6 px-6">Costo</th>
          <th class="pb-4 pt-6 px-6">Precio</th>
          <th class="pb-4 pt-6 px-6">Precio Bs</th>
          <th v-if="auth.user.role != 'supersales'" class="pb-4 pt-6 px-6">Ganancia</th>
          <th></th>
          
        </tr>
        <tr v-for="product in products.data" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              <img v-if="product.photo" class="block -my-2 mr-2 w-5 h-5 rounded-full" :src="product.photo" />
              {{ product.code }}
            </Link>
          </td>
          <!-- <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getDeposits(product.inventory) }}
            </Link>
          </td> -->
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product?.product_category?.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product?.brand.name }}
            </Link>
          </td>
          <td class="border-t">
            <span v-for="productDeposit in product.product_deposits" :key="productDeposit.id" :href="`products/${product.id}`" class="bg-blue-200 p-2 rounded m-2">
              {{productDeposit.deposit.name}}: {{ productDeposit.quantity }}
            </span>
          </td>
          <td v-if="auth.user.role != 'supersales'" class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product?.cost }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product?.prices[0]['price'] }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{(product?.prices[0]['price'] * change).toFixed(2)}}
            </Link>
          </td>
          <td v-if="auth.user.role != 'supersales'" class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product?.prices[0]['price'] - product?.cost }}
            </Link>
          </td>
        </tr>
        <tr v-if="!products">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="products.meta.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'

export default {
  components: {
    SelectInput,
    TextInput,
    SearchFilter,
    Pagination,
    Head,
    Icon,
    Link,
  },
  layout: Layout,
  props: {
    products: Object,
    groups: Object,
    csrf: String,
    brands: Object,
    parts: Object,
    models: Object,
    deposits: Object,
    regions: Object,
    change: Object,
    cop_change: Object,
    auth: Object
  },
  data() {
    return {
      // eslint-disable-next-line no-unused-vars
      getDeposits(inventory) {
        let deposits = ''
        for (let inventory of inventory) {
          deposits += `${inventory?.deposit?.name}, `
        }

        return deposits
      },
      // eslint-disable-next-line no-unused-vars
      getQuantity(inventory) {
        let quantity = 0
        for (let inventory of inventory) {
          quantity += inventory.quantity
        }

        return quantity
      },
      searchForm: false,
      form: this.$inertia.form({
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: '',
          region_id: '',
        },
      }),
      imported: false,
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/products', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    back() {
      window.history.back()
    },
  },
}
</script>
