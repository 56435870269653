<template>
    <div>
      <Head :title="`Editar tasa de cambio`" />
      <h1 class="mb-8 text-3xl font-bold">
        <Link class="text-indigo-400 hover:text-indigo-600" href="/taxes">Tasas de cambio</Link>
        <span class="text-indigo-400 font-medium">/</span>
        {{ form.change }}
      </h1>
      <div class="max-w-3xl bg-white rounded-xl shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input type="number" step="0.01" v-model="form.change" :error="form.errors.change" class="pb-8 pr-6 w-full lg:w-1/2" label="Tasa de cambio" />
            <text-input type="number" step="0.000001" v-model="form.cop_change" :error="form.errors.cop_change" class="pb-8 pr-6 w-full lg:w-1/2" label="Tasa de cambio pesos" />
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <!-- <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Eliminar tasa de cambio</button> -->
            <loading-button :loading="form.processing" class="btn-blue ml-auto" type="submit">Actualizar tasa de cambio</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      TextInput,
    },
    layout: Layout,
    props: {
      taxe: Object
    },
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          change: this.taxe.change,
          cop_change: this.taxe.cop_change
        }),
      }
    },
    methods: {
      update() {
        this.form.put(`/taxes/${this.taxe.id}`)
      },
      destroy() {
        if (confirm('Estas seguro que quieres eliminar esta tasa de cambio?')) {
          this.$inertia.delete(`/taxes/${this.taxe.id}`)
        }
      },
    },
  }
  </script>
  