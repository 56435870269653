export const getState = (state) => {
  switch (state) {
  case 'request':
    return 'Solicitud'
    break
  case 'estimate':
    return 'Presupuesto'
    break
  case 'pay':
    return 'Pago'
    break
  case 'payRevition':
    return 'Pago en revision'
    break
  case 'dispatch' :
    return 'Despachado'
    break
  case 'delivery':
    return 'Entregado'
    break
  case 'finish':
    return 'Finalizado'
    break
  }
  return 'Estatus no valido'
}
