<template>
  <div>
    <Head title="Gastos" />
    <app-toolbar name="Gasto" namePlural="Gastos" createHref="/spents/create" :exportButtons="false">
      <template v-slot:search>
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset"> </search-filter>
      </template>
    </app-toolbar>
    <div class="bg-white rounded-xl shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Nombre</th>
            <th class="pb-4 pt-6 px-6">Categoria</th>
            <th class="pb-4 pt-6 px-6">Fecha</th>
            <th class="pb-4 pt-6 px-6">Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="spent in spents.data" :key="spent.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/spents/${spent.id}/edit`">
                {{ spent.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/spents/${spent.id}/edit`">
                {{ spent.category.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/spents/${spent.id}/edit`">
                {{ spent.date }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/spents/${spent.id}/edit`"> ${{ spent.amount }} </Link>
            </td>
          </tr>
          <tr v-if="spents.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="4">No encontramos gastos.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination class="mt-6" :links="spents.meta.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import AppToolbar from '@/Shared/AppToolbar.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    AppToolbar,
  },
  layout: Layout,
  props: {
    filters: Object,
    spents: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/spents', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
