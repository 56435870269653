<template>
    <div>
      <Head :title="`Editar marca`" />
      <h1 class="mb-8 text-3xl font-bold">
        <Link class="text-indigo-400 hover:text-indigo-600" href="/spents">Gasto</Link>
        <span class="text-indigo-400 font-medium">/</span>
        {{ form.name }}
      </h1>
      <div class="max-w-3xl bg-white rounded-xl shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
            <text-input v-model="form.date" :error="form.errors.date" class="pb-8 pr-6 w-full lg:w-1/2" type="date" label="Fecha" />
            <text-input v-model="form.amount" :error="form.errors.amount" class="pb-8 pr-6 w-full lg:w-1/2" label="Monto" />
            <select-input v-model="form.spent_category_id" :error="form.errors.spent_category_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Categoria">
                <option value="">Seleccionar</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{category.name}}
                </option>
            </select-input>
            <select-input v-model="form.method" :error="form.errors.method" class="pb-8 pr-6 w-full lg:w-1/2" label="Método">
                <option value="">Seleccionar</option>
                <option value="efectivo">Efectivo</option>
                <option value="transferencia">Transferencia</option>
                <option value="pago-movil">Pago Movil</option>
                <option value="zelle">Zelle</option>
            </select-input>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Eliminar gasto</button>
            <loading-button :loading="form.processing" class="btn-blue ml-auto" type="submit">Actualizar gasto</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      TextInput,
      SelectInput
    },
    layout: Layout,
    props: {
      spent: Object,
      categories: Object
    },
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          name: this.spent?.name,
          amount: this.spent.amount,
          date: this.spent?.date,
          method: this.spent?.method,
          spent_category_id: this.spent.spent_category_id
        }),
      }
    },
    methods: {
      update() {
        this.form.put(`/spents/${this.spent.id}`)
      },
      destroy() {
        if (confirm('Estas seguro que quieres eliminar esta gastos?')) {
          this.$inertia.delete(`/spents/${this.spent.id}`)
        }
      },
    },
  }
  </script>
  